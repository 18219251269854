import React, { useState, useEffect } from 'react';
import { PAGES, LINKS_PATHNAME } from './constants';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import Keycloak from 'keycloak-js';
import { Provider } from 'react-redux';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import svDateFnsLocale from 'date-fns/locale/sv';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ApolloClient, ApolloProvider, InMemoryCache, gql, useLazyQuery } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import * as colors from './colors';
import { createDeepCopy } from './utility-functions';
import store from './redux/store';
import { Alarms, Deviations, Map, Automation, Administration, Overview, Properties, Links, Charts } from './pages';
import Reports from './pages/NewReport';
import {
	setHasFinishedLoading,
	setSensors,
	setProperties,
	setSensorGroups,
	setDigitalTwins,
	setDigitalTwinTags,
	setDigitalTwinPerspectives,
	setUserInfo,
	setCOs,
	setCORegisters,
	setCORegisterOffsets,
	setActions,
	setDeviations,
	setFileRecords,
	setAlarmPeriodSensors,
	setCameras,
	setExternalControlPanels,
	updateSensors,
	updateSensorGroups,
	updateUserInfo,
	addCallback,
	removeCallback,
} from './redux/actionCreators';
import NavigationBarHandler from './components/NavigationBarHandler';
import { Http } from '@material-ui/icons';

const KEYCLOAK_CONFIG = {
	url: process.env.REACT_APP_KEYCLOAK_URL,
	realm: process.env.REACT_APP_KEYCLOAK_REALM,
	clientId: process.env.REACT_APP_KEYCLOAK_CLIENTID,
};

document.body.style.backgroundColor = colors.bgcolor2;

const GET_MULTIDATA = gql`
	query {
		getSensorLocations {
			locationid
			city
			street
			cadastral
			area
			latitude
			longitude
			AI
			status
		}
		getSensorGroup {
			sensorgroupid
			name
			locationid
			digitaltwintagid
			digitaltwinperspectiveid
			externalcontrolpanelid
		}
		getDigitalTwins {
			digitaltwinid
			model
			locationid
			label
		}
		getDigitalTwinTags {
			digitaltwintagid
			digitaltwinid
			label
			description
			mediaurl
			sensorids
			sensorgroupids
			positionx
			positiony
			positionz
			normalx
			normaly
			normalz
		}
		getDigitalTwinPerspectives {
			digitaltwinperspectiveid
			digitaltwinid
			sweep
			rotationx
			rotationy
			zoom
		}
		getCOs {
			coid
			locationid
			name
			coaddress
		}
		getCORegisters {
			coregisterid
			coregisterref
			coid
			name
			latestvalue
			latesttimestamp
			unit
			classification
			multiplier
		}
		getCORegisterOffsets {
			coregisterid
			offset
			enddate
			originalvalue
		}
		getFileRecords {
			id
			locationid
			name
			displayname
			dateuploaded
			uploaderid
			classification
		}
		getAlarmPeriodSensors {
			alarmperiodid
			sensorid
		}
		getDeviations {
			deviationid
			locationid
			title
			description
			datecreated
			confirmcomment
			dateconfirmed
			digitaltwintagid
			digitaltwinperspectiveid
		}
		getCameras {
			cameraid
			locationid
			label
			rtspaddress
		}
		getExternalControlPanels {
			externalcontrolpanelid
			locationid
			url
			ip
			port
			ducid
			label
			serverip
		}
		getSensorView {
			sensorid
			sensorref
			name
			unit
			classification
			minvalue
			maxvalue
			lowerthreshold
			upperthreshold
			periodicity
			locationid
			city
			street
			cadastral
			area
			longitude
			latitude
			value
			timestamp
			sensorgroupid
			groupname
			multiplier
			alarmgraceminutes
			digitaltwintagid
			digitaltwinperspectiveid
			externalcontrolpanelid
		}
	}
`;
const GET_SENSORSANDGROUPS = gql`
	query ($sensorGroupFilter: SensorGroupFilter, $sensorViewFilter: SensorViewFilter) {
		getSensorGroup(filter: $sensorGroupFilter) {
			sensorgroupid
			name
			locationid
			digitaltwintagid
			digitaltwinperspectiveid
			externalcontrolpanelid
		}
		getSensorView(filter: $sensorViewFilter) {
			sensorid
			sensorref
			name
			unit
			classification
			minvalue
			maxvalue
			lowerthreshold
			upperthreshold
			periodicity
			locationid
			city
			street
			cadastral
			area
			longitude
			latitude
			value
			timestamp
			sensorgroupid
			groupname
			multiplier
			alarmgraceminutes
			digitaltwintagid
			digitaltwinperspectiveid
			externalcontrolpanelid
		}
	}
`;

const theme = createMuiTheme({
	palette: {
		primary: {
			main: colors.primary,
		},
		secondary: {
			main: colors.accent,
		},
	},
	typography: {
		useNextVariants: true,
		fontFamily: [
			'Roboto',
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
	},
});

const REFETCH_DELAY_MS = 7500;

function updateToken(seconds, keycloak) {
	return keycloak
		.updateToken(seconds)
		.then(refreshed => {
			if (refreshed)
				store.dispatch(
					updateUserInfo({
						accessToken: keycloak.token,
					})
				);
		})
		.catch(() => console.error('Failed to refresh keycloak-token, or the session has expired.'));
}

function getFormattedSensorView(data) {
	return data.map(sen => {
		const mul = sen.multiplier || 1;
		return {
			...sen,
			multiplier: mul,
			value: sen.value && sen.value * mul,
			lowerthreshold: sen.lowerthreshold && sen.lowerthreshold * mul,
			upperthreshold: sen.upperthreshold && sen.upperthreshold * mul,
			timestamp: new Date(sen.timestamp || '2000'),
		};
	});
}

function App() {
	const [keycloak, setKeycloak] = useState();
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [apolloClient, setApolloClient] = useState();

	const { t } = useTranslation();

	const [getMultiData] = useLazyQuery(GET_MULTIDATA, {
		client: apolloClient,
		onCompleted: res => {
			store.dispatch(setHasFinishedLoading(true));
			if (res.getSensorLocations) store.dispatch(setProperties(createDeepCopy(res.getSensorLocations)));
			if (res.getSensorGroup) store.dispatch(setSensorGroups(createDeepCopy(res.getSensorGroup)));
			if (res.getDigitalTwins) store.dispatch(setDigitalTwins(createDeepCopy(res.getDigitalTwins)));
			if (res.getDigitalTwinTags) store.dispatch(setDigitalTwinTags(createDeepCopy(res.getDigitalTwinTags)));
			if (res.getDigitalTwinPerspectives) store.dispatch(setDigitalTwinPerspectives(createDeepCopy(res.getDigitalTwinPerspectives)));
			if (res.getCOs) store.dispatch(setCOs(createDeepCopy(res.getCOs)));
			if (res.getCORegisters)
				store.dispatch(
					setCORegisters(
						res.getCORegisters.map(cor => ({
							...cor,
							latestvalue: (cor.multiplier || 1) * cor.latestvalue,
							multiplier: cor.multiplier || 1,
						}))
					)
				);
			if (res.getCORegisterOffsets)
				store.dispatch(
					setCORegisterOffsets(
						res.getCORegisterOffsets.map(off => {
							const mul = res.getCORegisters?.find(reg => reg.coregisterid === off.coregisterid)?.multiplier || 1;
							return {
								...off,
								offset: mul * off.offset,
								originalvalue: mul * off.originalvalue,
							};
						})
					)
				);
			if (res.getActions) store.dispatch(setActions(createDeepCopy(res.getActions)));
			if (res.getFileRecords) store.dispatch(setFileRecords(createDeepCopy(res.getFileRecords)));
			if (res.getAlarmPeriodSensors) store.dispatch(setAlarmPeriodSensors(createDeepCopy(res.getAlarmPeriodSensors)));
			if (res.getDeviations) store.dispatch(setDeviations(res.getDeviations));
			if (res.getCameras) store.dispatch(setCameras(createDeepCopy(res.getCameras)));
			if (res.getExternalControlPanels) store.dispatch(setExternalControlPanels(createDeepCopy(res.getExternalControlPanels)));
			if (res.getSensorView) store.dispatch(setSensors(getFormattedSensorView(res.getSensorView)));
		},
	});
	const [getSensorsAndGroups] = useLazyQuery(GET_SENSORSANDGROUPS, {
		client: apolloClient,
		onCompleted: ({ getSensorGroup, getSensorView }) => {
			store.dispatch(updateSensorGroups(createDeepCopy(getSensorGroup)));
			store.dispatch(updateSensors(getFormattedSensorView(getSensorView)));
		},
		fetchPolicy: 'no-cache',
	});

	useEffect(() => {
		if (window.location.pathname.startsWith(LINKS_PATHNAME)) {
			initializeApolloClient();
			return;
		}

		const keycloak = Keycloak(KEYCLOAK_CONFIG);
		keycloak.init({ onLoad: 'login-required', promiseType: 'native', checkLoginIframe: false }).then(authenticated => {
			setKeycloak(keycloak);
			setIsAuthenticated(authenticated);

			if (authenticated) {
				keycloak.loadUserInfo().then(userInfo => {
					store.dispatch(
						setUserInfo({
							id: userInfo.sub,
							username: userInfo.preferred_username,
							email: userInfo.email,
							phonenumber: userInfo.phonenumber,
							firstName: userInfo.given_name,
							lastName: userInfo.family_name,
							propertyAccess: userInfo.propertyAccess,
							sensorSubscriptions: userInfo.sensorSubscriptions,
							energyPrices: userInfo.energyPrices,
							lastReadNotificationId: userInfo.lastReadNotificationId,
							reportSensors: userInfo.reportSensors,
							reportProperties: userInfo.reportProperties,
							reportRecipients: userInfo.reportRecipients,
							reportOptions: userInfo.reportOptions,
							favoritePropertyIds: userInfo.favoritePropertyIds,
							favoritePropertyAutomationIds: userInfo.favoritePropertyAutomationIds,
							authorizationAccess: userInfo.authorizationAccess,
							authorizationOptions: userInfo.authorizationOptions,
							accessToken: keycloak.token,
							customerName: userInfo.customerName,
							superPower: userInfo.superPower,
							betaUserOptions: userInfo.betaUserOptions,
						})
					);

					// // !TEMP
					// if (
					// 	userInfo.preferred_username.startsWith('developer') ||
					// 	userInfo.preferred_username.startsWith('shane') ||
					// 	userInfo.preferred_username.startsWith('tobiash00@gmail.com') ||
					// 	userInfo.preferred_username.startsWith('sibe19@student.bth.se')
					// )
					// 	i18n.changeLanguage('en');
				});

				// Check if the token is valid for less than a minute every 45 seconds, and refreshes it if so.
				// Then update the token in userInfo in Redux, but not for the Apollo Client because it somehow does it itself behind the scenes...
				setInterval(() => updateToken(60, keycloak), 45000);

				store.dispatch(
					addCallback('on-update-report-options', 'update-accesstoken', () => updateToken(Number.MAX_SAFE_INTEGER, keycloak))
				);

				initializeApolloClient(keycloak);
			}
		});
	}, []);

	useEffect(() => {
		if (!apolloClient) return;
		getMultiData();

		store.dispatch(
			addCallback('on-update-importedregisters', 'refetch-sensors-and-groups', locationid =>
				setTimeout(
					() =>
						getSensorsAndGroups({
							variables: {
								sensorGroupFilter: { locationids: [locationid] },
								sensorViewFilter: { locationids: [locationid] },
							},
						}),
					REFETCH_DELAY_MS
				)
			)
		);
		return () => store.dispatch(removeCallback('on-update-importedregisters', 'refetch-sensors-and-groups'));
		// eslint-disable-next-line
	}, [apolloClient]);

	function initializeApolloClient(keycloak) {
		setApolloClient(
			new ApolloClient({
				cache: new InMemoryCache(),
				link: setContext((_, { headers }) => ({
					headers: {
						...headers,
						[keycloak?.token && 'authorization']: keycloak?.token,
						[window.location.pathname.startsWith(LINKS_PATHNAME) && 'X-Link-Code']: window.location.pathname.split('/')[2],
					},
				})).concat(createUploadLink({ uri: /* 'http://localhost:4000'  */process.env.REACT_APP_GRAPHQL_SERVER_URL })),
			})
		);
	}

	return (
		<MuiThemeProvider theme={theme}>
			<MuiPickersUtilsProvider utils={DateFnsUtils} locale={svDateFnsLocale}>
				<CssBaseline>
					<Provider store={store}>
						<Router>
							{apolloClient ? (
								<ApolloProvider client={apolloClient}>
									{window.location.pathname.startsWith(LINKS_PATHNAME) ? (
										<Links />
									) : keycloak ? (
										isAuthenticated ? (
											<>
												<NavigationBarHandler />

												{/* Offset from top applied to all center elements */}
												<div style={{ height: '3rem' }} />

												{/* Offset from left applied to all center elements */}
												<div style={{ marginLeft: isMobile ? '0rem' : '5rem' }}>
													<Switch>
														<Route exact path='/' component={Overview} />
														<Route path={'/' + PAGES.overview.id} component={Overview} />
														<Route path={'/' + PAGES.properties.id} component={Properties} />
														<Route path={'/' + PAGES.map.id} component={Map} />
														<Route path={'/' + PAGES.alarms.id} component={Alarms} />
														{/*<Route path={'/' + PAGES.actions.id} component={Actions} />*/}
														<Route path={'/' + PAGES.deviations.id} component={Deviations} />
														{/* <Route path={'/' + PAGES.analysis.id} component={Analysis} /> */}
														<Route path={'/' + PAGES.automation.id} component={Automation} />
														<Route path={'/' + PAGES.reports.id} component={Reports} />
														{/* <Route path={'/' + PAGES.issues.id} component={Issues} /> */}
														{/* <Route path={'/' + PAGES.assignments.id} component={Assignments} /> */}
														<Route path={'/' + PAGES.administration.id} component={Administration} />
														<Route path={'/' + PAGES.charts.id} component={Charts} />
													</Switch>
												</div>

												{/* Offset from bottom applied to all center elements */}
												<div style={{ height: '3rem' }} />
											</>
										) : (
											<div>{t('app.failed')}</div>
										)
									) : (
										<div>{t('app.loading')}</div>
									)}
								</ApolloProvider>
							) : (
								<div>{t('generic.loading')}</div>
							)}
						</Router>
					</Provider>
				</CssBaseline>
			</MuiPickersUtilsProvider>
		</MuiThemeProvider>
	);
}

export { App };
