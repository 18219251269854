import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { gql, useQuery } from '@apollo/client';
import { STORE, getStateVariables } from '../redux/selectors';
import { createDeepCopy, getFormattedNumber } from '../utility-functions';
import Dashboard from './Dashboard';
import { LineChart, BarChart, WeatherChart } from './DashBoardCharts';
import './DashboardList.css';
import { add } from 'date-fns';

import speedometer from '../assets/dashboard/speedometer2.svg';
import nail from '../assets/dashboard/nail.svg';

const GET_SENSOR_VIEW = gql`
	query ($filter: SensorViewFilter) {
		getSensorView(filter: $filter) {
			sensorid
			sensorref
			name
			unit
			classification
			minvalue
			maxvalue
			lowerthreshold
			upperthreshold
			periodicity
			locationid
			city
			street
			cadastral
			area
			longitude
			latitude
			value
			timestamp
			sensorgroupid
			groupname
			multiplier
			alarmgraceminutes
			digitaltwintagid
			digitaltwinperspectiveid
		}
	}
`;

const GET_SENSOR_DATA = gql`
	query ($filter: SensorDataFilter!) {
		getSensorData(filter: $filter) {
			sensorid
			sensordata {
				x
				y
			}
		}
	}
`;

const GET_SENSOR_DATA_NEW = gql`
	query ($filter: SensorDataNewFilter!) {
		getSensorDataNew(filter: $filter) {
			sensorid
			sensordata {
				x
				y
			}
		}
	}
`;

const GET_SINGLE_CLOSEST_SENSOR_DATA = gql`
	query ($filter: SensorCloestFilter!) {
		getClosestSingleSensordata(filter: $filter) {
			sensorid
			sensordata {
				x
				y
			}
		}
	}
`;

function Speedometer({ value }) {
	const [rotationAngle, setRotationAngle] = useState(0);
	const middleStart = 6;
	const middleEnd = 12;
	const high = 18;
	useEffect(() => {
		let needleAngle;

		if (value < middleStart) {
			needleAngle = -90 + (value / middleStart) * 60;
		} else if (value >= middleStart && value <= middleEnd) {
			needleAngle = -30 + ((value - middleStart) / (middleEnd - middleStart)) * 60;
		} else if (value > middleEnd && value < high) {
			needleAngle = 30 + ((value - middleEnd) / (high - middleEnd)) * 60;
		} else {
			needleAngle = 90;
		}

		setRotationAngle(needleAngle);
	}, [value]);

	return (
		<>
			<p className='speedometer-title'>Solenergi</p>
			<p className='speedometer-value'>{value} kW</p>
			<div className='speedometer-container'>
				<div className='speedometer'>
					<img src={speedometer} width='100%' height='100%' alt='speedometer'/>
				</div>
				<div className='speedometer-nail'>
					<img
						src={nail}
						style={{
							transformOrigin: 'center bottom',
							transform: `rotate(${rotationAngle}deg)`,
						}}
						width='5%'
						height='100%'
						alt='speedometer-nail'
					/>
				</div>
			</div>
		</>
	);
}
function getFormattedDate(year, month, day = 1) {
	return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
}

function getCurrentDates() {
	const currentDate = new Date();
	currentDate.setHours(0, 0, 0, 0);
	const year = currentDate.getFullYear();
	const month = currentDate.getMonth() + 1;
	const day = currentDate.getDate();
	const dayOfWeek = currentDate.getDay();
	const daysUntilMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
	const mondayDate = new Date(currentDate);
	mondayDate.setDate(day - daysUntilMonday);

	const currentYear = getFormattedDate(year, 1);
	const nextYear = getFormattedDate(year + 1, 1);

	const currentMonth = getFormattedDate(year, month);
	const nextMonth = getFormattedDate(year, month + 1);

	const currentWeek = getFormattedDate(mondayDate.getFullYear(), mondayDate.getMonth() + 1, mondayDate.getDate());
	const nextMonday = add(mondayDate, {days: 7});
	const nextWeek = getFormattedDate(nextMonday.getFullYear(), nextMonday.getMonth() + 1, nextMonday.getDate());

	const currentDay = getFormattedDate(year, month, day);
	const nextDay = getFormattedDate(year, month, day + 1);

	return {
		currentYear,
		nextYear,
		currentMonth,
		nextMonth,
		currentWeek,
		nextWeek,
		currentDay,
		nextDay,
	};
}

function GetIntervalData({ sensor, totalValue, multiplier, valueTextStyle, setUnitsCallback, unit, oldUnits }) {
	const units = { ...oldUnits };
	const [weekValue, setWeekValue] = useState('');
	const [monthValue, setMonthValue] = useState('');
	const [yearValue, setYearValue] = useState('');

	const { currentYear, nextYear, currentMonth, nextMonth, currentWeek, nextWeek, currentDay, nextDay} = getCurrentDates();

	const useSensorDataLatest = (timestamps) => {
		const { loading, data, error, refetch } = useQuery(GET_SINGLE_CLOSEST_SENSOR_DATA, {
			variables: {
				filter: {
					sensorids: [sensor],
					timestamps: timestamps,
				},
			},
		});

		return { loading, data, error, refetch };
	};

	const {
		loading: loadingYear,
		data: dataYear,
		error: errorYear,
		refetch: refetchYear,
	} = useSensorDataLatest([currentYear]);

	const {
		loading: loadingMonth,
		data: dataMonth,
		error: errorMonth,
		refetch: refetchMonth,
	} = useSensorDataLatest([currentMonth]);

	const {
		loading: loadingWeek,
		data: dataWeek,
		error: errorWeek,
		refetch: refetchWeek,
	} = useSensorDataLatest([currentWeek]);

	useEffect(() => {
		const intervalId = setInterval(() => {
			refetchWeek();
			refetchMonth();
			refetchYear();
		}, 3600000); // 1 hour

		return () => clearInterval(intervalId);
	}, []);

	useEffect(() => {
		if (!loadingWeek && !errorWeek && dataWeek && !loadingMonth && !errorMonth && dataMonth && !loadingYear && !errorYear && dataYear) {
			let formattedData = [
				{ value: totalValue - dataWeek.getClosestSingleSensordata[0].sensordata[0].y * multiplier, unit: 'weekUnit' },
				{ value: totalValue - dataMonth.getClosestSingleSensordata[0].sensordata[0].y * multiplier, unit: 'monthUnit' },
				{ value: totalValue - dataYear.getClosestSingleSensordata[0].sensordata[0].y * multiplier, unit: 'yearUnit' },
			];
			switch (unit) {
				case 'electricity':
					formattedData.forEach(data => {
						if (data.value > 999) {
							data.value /= 1000;
							units[data.unit] = 'MWh';
						}
					});

					break;
				case 'heating':
					formattedData.forEach(data => {
						if (data.value < 1) {
							data.value *= 1000;
							units[data.unit] = 'kWh';
						}
					});
					break;
				case 'solar':
					formattedData.forEach(data => {
						if (data.value > 999) {
							data.value /= 1000;
							units[data.unit] = 'MWh';
						}
					});

					break;
				default:
					break;
			}
			setUnitsCallback(units);

			for (let i = 0; i < formattedData.length; i++) {
				if ((unit === 'm3' || unit === 'coldWater') && (formattedData[i].value < 10)) {
					formattedData[i].value = formattedData[i].value.toFixed(1);
				}
				else if (formattedData[i].value < 10) {
					formattedData[i].value = formattedData[i].value.toPrecision(2);
				}
				else {
					formattedData[i].value = Math.floor(formattedData[i].value);
				}
			}

			setWeekValue(formattedData[0].value);
			setMonthValue(formattedData[1].value);
			setYearValue(formattedData[2].value);
		}
	}, [dataWeek, loadingWeek, errorWeek, loadingMonth, errorMonth, dataMonth, loadingYear, errorYear, dataYear]);

	return (
		<>
			{!dataWeek || !dataMonth || !dataYear ? (
				<p>...</p>
			) : (
				<div style={valueTextStyle}>
					<p>{weekValue}</p>
					<p>{monthValue}</p>
					<p>{yearValue}</p>
				</div>
			)}
		</>
	);
}

function formatYYMM(date) {
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	return `${year}-${month}`;
}

function GetWeatherData({ children }) {
	const { currentDay } = getCurrentDates();
	const [weatherData, setWeatherData] = useState([]);
	const [apiData, setApiData] = useState([]);

	//Weather forecast from SMHI
	const url = 'https://opendata-download-metfcst.smhi.se/api/category/pmp3g/version/2/geotype/point/lon/16.30/lat/59.21/data.json';

	const { loading, data, error, refetch } = useQuery(GET_SENSOR_DATA, {
		variables: {
			filter: {
				sensorids: [8745],
				timestamp_gte: currentDay + ' 09:00',
				timestamp_lte: currentDay + ' 21:59',
				timestamp_interval: 60,
				interval_type: 'mean',
			},
		},
	});

	const fetchData = () => {
		fetch(url)
			.then(response => {
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				return response.json();
			})
			.then(data => {
				if (data && data.timeSeries) {
					const extractedData = data.timeSeries.slice(0, 24).map(item => {
						const timestamp = item.validTime;
						const temperature = item.parameters.find(param => param.name === 't')?.values[0] || null;
						return { timestamp, temperature };
					});
					setApiData(extractedData);
				}
			})
			.catch(error => {
				console.error('There was a problem with the fetch operation:', error);
			});
	};

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		const intervalId = setInterval(() => {
			refetch();
			fetchData();
		}, 120000); // every 2 min
		return () => clearInterval(intervalId);
	}, []);

	useEffect(() => {
		if (!loading && !error && data && data.getSensorData && apiData) {
			const currentDate = new Date();
			const currentHour = currentDate.getHours();

			const values = data.getSensorData;
			let formattedWeatherData = [];
			for (let i = 0; i < values[0].sensordata.length; i++) {
				if (values[0].sensordata[i].x.slice(11, 13) < currentHour) {
					formattedWeatherData.push({
						Month: values[0].sensordata[i].x.slice(11, 16),
						Energy: parseInt(values[0].sensordata[i].y * 0.01), // multiplier
					});
				} else {
					const currentTimestamp = values[0].sensordata[i].x.slice(11, 16);
					const forecastedData = apiData.find(item => item.timestamp.slice(11, 16) === currentTimestamp);

					formattedWeatherData.push({
						Month: currentTimestamp,
						Energy: forecastedData ? forecastedData.temperature : null,
					});
				}
			}
			setWeatherData(formattedWeatherData);
		}
	}, [loading, error, data, apiData]);
	if (loading) return '...';
	if (error) return 'Error';

	return children({ weatherData });
}

function GetData({ children }) {
	const getFilter = () => {
		const currentDate = new Date();
		const threeYearsAgo = new Date(currentDate);
		threeYearsAgo.setFullYear(threeYearsAgo.getFullYear() - 5);
		const minimumDate = new Date('2022-07-01');
		const finalDate = threeYearsAgo < minimumDate ? minimumDate : threeYearsAgo;
		const formatted_gte = `${finalDate.toISOString().slice(0, 10)} 08:00`;
		const formatted_lte = `${currentDate.toISOString().slice(0, 10)} 08:00`;

		const newFilter = {
			sensorids: [9228, 8663],
			timestamp_gte: formatted_gte,
			timestamp_lte: formatted_lte,
			timestamp_interval: 1440, // Collect 1 datapoint per day
			interval_type: 'mean',
		};

		return newFilter;
	};

	const [chartData, setChartData] = useState([]);
	const [formattedEnergyData, setFormattedEnergyData] = useState([]);
	const [formattedHeatingData, setFormattedHeatingData] = useState([]);
	const [barChartKeys, setBarChartKeys] = useState([]);
	const [filter, setFilter] = useState(getFilter());

	const { loading, data, error, refetch } = useQuery(GET_SENSOR_DATA, {
		variables: {
			filter: filter,
		},
	});

	useEffect(() => {
		const intervalId = setInterval(() => {
			const newFilter = getFilter();
			setFilter(newFilter);

			refetch({
				filter: newFilter,
			});
		}, 86400000); // 1 day
		return () => clearInterval(intervalId);
	}, []);

	useEffect(() => {
		if (!loading && !error && data && data.getSensorData) {
			const sensors = data.getSensorData;

			if (sensors.length > 0) {
				const transformedData = sensors.map(sensor => ({
					id: `Sensor ${sensor.sensorid}`,
					data: sensor.sensordata.map(dataPoint => ({
						x: formatYYMM(new Date(dataPoint.x)),
						y: dataPoint.y,
					})),
				}));

				const swedishMonths = [
					'Januari',
					'Februari',
					'Mars',
					'April',
					'Maj',
					'Juni',
					'Juli',
					'Augusti',
					'September',
					'Oktober',
					'November',
					'December',
				];

				let energyDataPerMonth = [];
				let heatingDataPerMonth = [];

				for (let j = 0; j < 2; j++) {
					let firstValueOfMonth = null;
					let lastvalueOfMonth = null;
					for (let i = 0; i < transformedData[0].data.length - 1; i++) {
						let month = transformedData[j].data[i + 1].x.slice(5, 7);

						if (transformedData[j].data[i].x.slice(5, 7) === month) {
							if (firstValueOfMonth === null) {
								firstValueOfMonth = transformedData[j].data[i].y;
							}
						} else {
							lastvalueOfMonth = transformedData[j].data[i].y;
//							const index = parseInt(transformedData[j].data[i].x.slice(5, 7));
							if (transformedData[j].id === 'Sensor 9228') {
								energyDataPerMonth.push({
									Month: transformedData[j].data[i].x,
									Energy: (lastvalueOfMonth - firstValueOfMonth) * 0.1, //multiplier
									year: transformedData[j].data[i].x.slice(0, 4),
								});
							} else {
								heatingDataPerMonth.push({
									Month: transformedData[j].data[i].x, //swedishMonthNames[index - 1].substring(0, 3),
									Energy: lastvalueOfMonth - firstValueOfMonth,
									year: transformedData[j].data[i].x.slice(0, 4),
								});
							}

							firstValueOfMonth = null;
							lastvalueOfMonth = null;
						}
					}
				}

				const energyDataObject = [];
				const heatingDataObject = [];

				for (let i = 0; i < 12; i++) {
					const EnergyMonthObject = {
						Month: swedishMonths[i].substring(0, 3),
					};
					const HeatingMonthObject = {
						Month: swedishMonths[i].substring(0, 3),
					};

					for (let j = 0; j < energyDataPerMonth.length; j++) {
						const monthIndex = parseInt(energyDataPerMonth[j].Month.slice(5, 7)) - 1;
						const year = energyDataPerMonth[j].Month.slice(0, 4);

						if (monthIndex === i) {
							EnergyMonthObject[year] = energyDataPerMonth[j].Energy;
						}
					}
					for (let j = 0; j < heatingDataPerMonth.length; j++) {
						const monthIndex = parseInt(heatingDataPerMonth[j].Month.slice(5, 7)) - 1;
						const year = heatingDataPerMonth[j].Month.slice(0, 4);

						if (monthIndex === i) {
							HeatingMonthObject[year] = heatingDataPerMonth[j].Energy;
						}
					}
					energyDataObject.push(EnergyMonthObject);
					heatingDataObject.push(HeatingMonthObject);
				}

				const uniqueYears = [];
				heatingDataObject.forEach(item => {
					Object.keys(item).forEach(key => {
						if (key !== 'Month' && !uniqueYears.includes(key)) {
							uniqueYears.push(key);
						}
					});
				});
				//order years
				uniqueYears.sort((a, b) => b - a);
				setBarChartKeys(uniqueYears);

				let combinedData = [];

				let years = [];

				for (let i = 0; i < uniqueYears.length; i++) {
					years.push([]);
				}

				uniqueYears.forEach((item, index) => {
					for (let i = 0; i < heatingDataPerMonth.length || i < energyDataPerMonth.length; i++) {
						const id = parseInt(energyDataPerMonth[i].Month.slice(5, 7), 10);
						if (energyDataPerMonth[i].Month.slice(0, 4) === item) {
							years[index].push({
								x: swedishMonths[id - 1],
								y: energyDataPerMonth[i].Energy + heatingDataPerMonth[i].Energy,
							});
						}
					}

					combinedData.push({
						id: item,
						data: years[index],
					});
				});

				setChartData(combinedData);
				setFormattedEnergyData(energyDataObject);
				setFormattedHeatingData(heatingDataObject);
			}
		}
	}, [loading, error, data]);

	if (loading) return '...';
	if (error) return 'Error';

	return children({ chartData, formattedEnergyData, barChartKeys, formattedHeatingData });
}

function convertUnitAndValue(unit, value) {
	if (!unit) return { unit, value };

	const currentUnit = unit?.toLowerCase();
	const containsW = currentUnit?.includes('w');
	//converts mW and kW to W. if its in W.
	if (containsW) {
		const prefix = currentUnit.slice(0, currentUnit.indexOf('w'));
		value = value * (prefix === 'm' ? 1000000 : prefix === 'k' ? 1000 : 1);
	}
	//get the updated value with possible t or m
	value = getFormattedNumber(value, false, true);

	//Check if it was in w kw or mw convert m to mw and t to kw
	if (value.includes('m')) {
		value = value.replace('m', '');
		unit = containsW ? `mW${unit.substring(currentUnit.indexOf('w') + 1)}` : 'm ' + unit;
	} else if (value.includes('t')) {
		value = value.replace('t', '');
		unit = containsW ? `kW${unit.substring(currentUnit.indexOf('w') + 1)}` : 't ' + unit;
	} else if (containsW) {
		unit = `W${unit.substring(currentUnit.indexOf('w') + 1)}`;
	}

	if (!unit) {
		unit = '';
	}

	return { unit, value };
}

function RenderWaterData({ warmRefValue, coldRefValue, valueTextStyle, titleTextStyle }) {
	const [coldUnits, setColdUnits] = useState({
		weekUnit: 'm',
		monthUnit: 'm',
		yearUnit: 'm',
	});
	const [warmUnits, setWarmUnits] = useState({
		weekUnit: 'm',
		monthUnit: 'm',
		yearUnit: 'm',
	});

	const setColdUnitsCallback = newUnits => {
		setColdUnits(newUnits);
	};
	const setWarmUnitsCallback = newUnits => {
		setWarmUnits(newUnits);
	};

	return (
		<>
			<div style={valueTextStyle}>
				<p className='water-title' style={titleTextStyle}>
					Vatten
				</p>
				<p className='warm-water'>Varmt</p>
				<p className='coldwater'>Kallt</p>
			</div>
			<div style={valueTextStyle}>
				<p className='week-water'>Vecka</p>
				<p className='month-water'>Månad</p>
				<p className='year-water'>I år</p>
			</div>
			<div className='waterData-container'>
				<GetIntervalData
					sensor={8677}
					totalValue={warmRefValue}
					multiplier={0.001}
					valueTextStyle={valueTextStyle}
					setUnitsCallback={setWarmUnitsCallback}
					unit={'m3'}
					oldUnits={warmUnits}
				></GetIntervalData>
			</div>
			<div style={valueTextStyle}>
				<p className='week-water-unit'>
					{warmUnits.weekUnit}
					<sup>3</sup>
				</p>
				<p className='month-water-unit'>
					{warmUnits.monthUnit}
					<sup>3</sup>
				</p>
				<p className='year-water-unit'>
					{warmUnits.yearUnit}
					<sup>3</sup>
				</p>
			</div>
			<div className='data2-container'>
				<GetIntervalData
					sensor={8662}
					totalValue={coldRefValue}
					multiplier={0.001}
					valueTextStyle={valueTextStyle}
					setUnitsCallback={setColdUnitsCallback}
					unit={'coldWater'}
					oldUnits={coldUnits}
				></GetIntervalData>
			</div>
			<div style={valueTextStyle}>
				<p className='week-second-unit'>
					{coldUnits.weekUnit}
					<sup>3</sup>
				</p>
				<p className='month-second-unit'>
					{coldUnits.monthUnit}
					<sup>3</sup>
				</p>
				<p className='year-second-unit'>
					{coldUnits.yearUnit}
					<sup>3</sup>
				</p>
			</div>
		</>
	);
}
function RenderTemperatureData({ insideTemp, outsideTemp, valueTextStyle, unit, titleTextStyle }) {

	return (
		<>
			<div>
				<div className='temperature-title' style={{...titleTextStyle, left: `${25.5}%`, }}>
					<p>Ute Temperatur</p>
				</div>

				<div className='row-container' style={{ left: `${27}%`, top: `${80.5}%` }}>
					<div className='temperature-inside' style={valueTextStyle}>
						<p>Just nu</p>
					</div>
					<div className='temp1' style={valueTextStyle}>
						{insideTemp.toFixed(1)}
					</div>
					<div>
						<p className='temperature-unit' style={valueTextStyle}>
							&deg;C
						</p>
					</div>
				</div>
			</div>
			<div className='weather-chart'>
				<GetWeatherData>{({ weatherData }) => <WeatherChart data={weatherData} color={'#d14d2c'} />}</GetWeatherData>
			</div>
		</>
	);
}
function RenderElecricityData({ refValue, currentValue, valueTextStyle, unit, titleTextStyle }) {
	const [units, setUnits] = useState({
		weekUnit: 'kWh',
		monthUnit: 'kWh',
		yearUnit: 'kWh',
	});

	const setUnitsCallback = newUnits => {
		setUnits(newUnits);
	};

	return (
		<>
			<div>
				<div className='electricity-title' style={titleTextStyle}>
					<p>El</p>
				</div>
				<div className='row-container' style={{ left: `${44}%` }}>
					<div className='time-container' style={valueTextStyle}>
						<p>Just nu</p>
						<p>Vecka</p>
						<p>Månad</p>
						<p>I år</p>
					</div>

					<div className='data-container' style={valueTextStyle}>
						<p>{(currentValue).toFixed(2)}</p>

						<GetIntervalData
							sensor={9228}
							totalValue={refValue}
							multiplier={0.1}
							valueTextStyle={valueTextStyle}
							setUnitsCallback={setUnitsCallback}
							unit={'electricity'}
							oldUnits={units}
						></GetIntervalData>
					</div>

					<div className='unit-container' style={valueTextStyle}>
						<p>{unit}</p>
						<p>{units.weekUnit}</p>
						<p>{units.monthUnit}</p>
						<p>{units.yearUnit}</p>
					</div>
				</div>
			</div>
		</>
	);
}
function RenderHeatingData({ refValue, currentValue, valueTextStyle, unit, titleTextStyle }) {
	const [units, setUnits] = useState({
		weekUnit: 'MWh',
		monthUnit: 'MWh',
		yearUnit: 'MWh',
	});

	const setUnitsCallback = newUnits => {
		setUnits(newUnits);
	};

	return (
		<>
			<div>
				<div className='heating-title' style={titleTextStyle}>
					<p>Värme</p>
				</div>
				<div className='row-container' style={{ left: `${63.5}%` }}>
					<div className='time-container' style={valueTextStyle}>
						<p>Just nu</p>
						<p>Vecka</p>
						<p>Månad</p>
						<p>I år</p>
					</div>

					<div className='data-container' style={valueTextStyle}>
						<p>{currentValue}</p>

						<GetIntervalData
							sensor={8663}
							totalValue={refValue}
							multiplier={0.001}
							valueTextStyle={valueTextStyle}
							setUnitsCallback={setUnitsCallback}
							unit={'heating'}
							oldUnits={units}
						></GetIntervalData>
					</div>

					<div className='unit-container' style={valueTextStyle}>
						<p>{unit}</p>
						<p>{units.weekUnit}</p>
						<p>{units.monthUnit}</p>
						<p>{units.yearUnit}</p>
					</div>
				</div>
			</div>
		</>
	);
}

function RenderSolarData({ refValue, currentValue, valueTextStyle, unit, titleTextStyle }) {
	const [units, setUnits] = useState({
		weekUnit: 'kWh',
		monthUnit: 'kWh',
		yearUnit: 'kWh',
	});

	const setUnitsCallback = newUnits => {
		setUnits(newUnits);
	};

	return (
		<>
			<div style={valueTextStyle}>
				<div className='solar-title' style={titleTextStyle}>
					<p>Solenergi</p>
				</div>
				<Speedometer value={ getFormattedNumber(currentValue, false, true)}></Speedometer>

				<div className='row-container' style={{ left: `${83}%` }}>
					<div className='time-container' style={valueTextStyle}>
						<p>Just nu</p>
						<p>Vecka</p>
						<p>Månad</p>
						<p>I år</p>
					</div>

					<div className='data-container' style={valueTextStyle}>
						<p>{getFormattedNumber(currentValue, false, true)}</p>

						<GetIntervalData
							sensor={9226}
							totalValue={refValue}
							multiplier={0.1}
							valueTextStyle={valueTextStyle}
							setUnitsCallback={setUnitsCallback}
							unit={'solar'}
							oldUnits={units}
						></GetIntervalData>
					</div>

					<div className='unit-container' style={valueTextStyle}>
						<p>{unit}</p>
						<p>{units.weekUnit}</p>
						<p>{units.monthUnit}</p>
						<p>{units.yearUnit}</p>
					</div>
				</div>
			</div>
		</>
	);
}

function DashboardList(props) {
	const [sensors, setSensors] = useState([]);
	const [, setIsFs] = useState(false); //needs to be
	const [percentageDifference, setPercentageDifference] = useState(1);
	const valueTextStyle = {
		fontSize: `${0.875 * percentageDifference}rem`,
	};
	const titleTextStyle = {
		fontSize: `${1.2 * percentageDifference}rem`,
	};
	const unitTextStyle = {
		fontSize: `${0.7 * percentageDifference}rem`,
		marginLeft: `${0.2 * percentageDifference}rem`,
		display: 'flex',
		alignItems: 'center',
	};

	const getSensorView = useQuery(GET_SENSOR_VIEW, {
		variables: {
			filter: {
				locationids: props.currentProperty === 311 || props.currentProperty === 312 ? [311, 312] : [props.currentProperty],
			},
		},
		onCompleted: ({ getSensorView }) =>
			setSensors(
				createDeepCopy(getSensorView).map(sen => ({
					...sen,
					value: sen.value * (sen.multiplier || 1),
				}))
			),
		fetchPolicy: 'no-cache',
		notifyOnNetworkStatusChange: true,
	});

	useEffect(() => {
		const intervalId = setInterval(() => getSensorView.refetch(), 20000);
		return () => clearInterval(intervalId);
		// eslint-disable-next-line
	}, []);

	function SetSosdal() {
		return [
			[sensors.find(sen => sen.sensorid === '6248'), sensors.find(sen => sen.sensorid === '6247')],
			[sensors.find(sen => sen.sensorid === '6252'), sensors.find(sen => sen.sensorid === '6251')],
			[sensors.find(sen => sen.sensorid === '6250'), sensors.find(sen => sen.sensorid === '6249')],
			[sensors.find(sen => sen.sensorid === '6262')],
			[sensors.find(sen => sen.sensorid === '6253')],
			[sensors.find(sen => sen.sensorid === '6264'), sensors.find(sen => sen.sensorid === '6263')],
		].map((sens, i) => {
			sens[0] = convertUnitAndValue(
				sens[0]?.unit,
				!isNaN(sens[0]?.value)
					? (Number(sens[0]?.value) || 0) + (Math.random() < 0.3 ? 0.1 * Math.random() * (sens[0]?.value || 0) : 0)
					: sens[0]?.value
			);

			if (sens.length === 2) sens[1] = convertUnitAndValue(sens[1]?.unit, sens[1]?.value);

			return (
				<Fragment key={i}>
					<div
						style={{
							display: 'flex',
							position: 'absolute',
							top: '80.2%',
							left: `${13.2 + i * 14.3}%`,
							fontWeight: '300',
							color: '#fffc',
							transform: 'translate(0, -50%)',
						}}
					>
						<div style={valueTextStyle}>{sens[0]?.value}</div>
						<div style={unitTextStyle}>{sens[0]?.unit || ''}</div>
					</div>

					{sens.length === 2 && (
						<div
							style={{
								display: 'flex',
								position: 'absolute',
								top: '84.5%',
								left: `${13.2 + i * 14.3}%`,
								fontWeight: '300',
								color: '#fffc',
								transform: 'translate(0,-50%)',
							}}
						>
							<div style={valueTextStyle}>{sens[1]?.value}</div>
							<div style={unitTextStyle}>{sens[1]?.unit || ''}</div>
						</div>
					)}
				</Fragment>
			);
		});
	}

	function SetDemoProperties() {
		return [
			[
				{ value: 21.3, unit: 'kW' },
				{ value: 10.2, unit: 'mWh' },
			],
			[
				{ value: 20, unit: 'W' },
				{ value: 9337, unit: 'mWh' },
			],
			[
				{ value: 22.6, unit: 'kw' },
				{ value: 36.3, unit: 'mWh' },
			],
			[{ value: 21.5, unit: '°C' }],
			[{ value: 12.8, unit: '°C' }],
			[
				{ value: 148, unit: 'W' },
				{ value: 6307, unit: 'kWh' },
			],
		].map((sens, i) => {
			sens[0] = convertUnitAndValue(
				sens[0]?.unit,
				!isNaN(sens[0]?.value)
					? (Number(sens[0]?.value) || 0) + (Math.random() < 0.3 ? 0.1 * Math.random() * (sens[0]?.value || 0) : 0)
					: sens[0]?.value
			);

			if (sens.length === 2) sens[1] = convertUnitAndValue(sens[1]?.unit, sens[1]?.value);

			return (
				<Fragment key={i}>
					<div
						style={{
							display: 'flex',
							position: 'absolute',
							top: '80.2%',
							left: `${13.2 + i * 14.3}%`,
							fontWeight: '300',
							color: '#fffc',
							transform: 'translate(0, -50%)',
						}}
					>
						<div style={valueTextStyle}>{sens[0]?.value}</div>
						<div style={unitTextStyle}>{sens[0]?.unit || ''}</div>
					</div>

					{sens.length === 2 && (
						<div
							style={{
								display: 'flex',
								position: 'absolute',
								top: '84.5%',
								left: `${13.2 + i * 14.3}%`,
								fontWeight: '300',
								color: '#fffc',
								transform: 'translate(0,-50%)',
							}}
						>
							<div style={valueTextStyle}>{sens[1]?.value}</div>
							<div style={unitTextStyle}>{sens[1]?.unit || ''}</div>
						</div>
					)}
				</Fragment>
			);
		});
	}

	function SetEskilstuna() {
		return [
			[sensors.find(sen => sen.sensorid === '8662'), sensors.find(sen => sen.sensorid === '8677')],
			[sensors.find(sen => sen.sensorid === '8745'), sensors.find(sen => sen.sensorid === '9173')],
			[sensors.find(sen => sen.sensorid === '9229'), sensors.find(sen => sen.sensorid === '9228')],
			[sensors.find(sen => sen.sensorid === '8665'), sensors.find(sen => sen.sensorid === '8663')],
			[sensors.find(sen => sen.sensorid === '9227'), sensors.find(sen => sen.sensorid === '9226')],
		].map((sens, i) => {
			if (sens[0]?.sensorid !== '8662' && sens[0]?.sensorid !== '9229' && sens[0]?.sensorid !== '9227' && sens[0]?.sensorid !== '8665') {
				sens[0] = convertUnitAndValue(
					sens[0]?.unit,
					!isNaN(sens[0]?.value)
						? (Number(sens[0]?.value) || 0) + (Math.random() < 0.3 ? 0.1 * Math.random() * (sens[0]?.value || 0) : 0)
						: sens[0]?.value
				);
			}
			return (
				<Fragment key={i}>
					{i === 0 && (
						<>
							<GetData>
								{({ chartData, formattedEnergyData, barChartKeys, formattedHeatingData }) => (
									<>
										<div className='linechart-container' style={valueTextStyle}>
											<p className='linechart-unit'>kWh</p>
											<p className='linechart-title'>Total energianvändning El & Värme</p>
											<div className='line-chart'>
												<LineChart data={chartData} />
											</div>
										</div>
										<div className='energyBarChart-continer' style={valueTextStyle}>
											<p className='energyBarChart-title'>El</p>
											<p className='energyBarChart-unit'>kWh</p>
											<div className='energyBarChart'>
												<BarChart
													data={formattedEnergyData}
													color={'#154c79'}
													barLabel={'El 12 mån'}
													style={valueTextStyle}
													barChartKeys={barChartKeys}
												/>
											</div>
										</div>

										<div className='heatingBarChart-container' style={valueTextStyle}>
											<p className='heatingBarChart-title'>Värme</p>
											<p className='heatingBarChart-unit'>kWh</p>
											<div className='heatingBarChart'>
												<BarChart
													data={formattedHeatingData}
													color={'#154c79'}
													barLabel={'Värme 12 mån'}
													style={valueTextStyle}
													barChartKeys={barChartKeys}
												/>
											</div>
										</div>
									</>
								)}
							</GetData>

							<RenderWaterData
								warmRefValue={sens[1]?.value}
								coldRefValue={sens[0]?.value}
								valueTextStyle={valueTextStyle}
								titleTextStyle={titleTextStyle}
							/>
						</>
					)}
					{i === 1 && (
						<RenderTemperatureData
							insideTemp={sens[1]?.value}
							outsideTemp={sens[0]?.value}
							valueTextStyle={valueTextStyle}
							unit={sens[0]?.unit}
							titleTextStyle={titleTextStyle}
						/>
					)}
					{i === 2 && (
						<RenderElecricityData
							refValue={sens[1]?.value}
							currentValue={sens[0]?.value}
							valueTextStyle={valueTextStyle}
							unit={sens[0]?.unit}
							titleTextStyle={titleTextStyle}
						/>
					)}
					{i === 3 && (
						<RenderHeatingData
							refValue={sens[1]?.value}
							currentValue={sens[0]?.value}
							valueTextStyle={valueTextStyle}
							unit={sens[0]?.unit}
							titleTextStyle={titleTextStyle}
						/>
					)}
					{i === 4 && (
						<RenderSolarData
							refValue={sens[1]?.value}
							currentValue={sens[0]?.value}
							valueTextStyle={valueTextStyle}
							unit={sens[0]?.unit}
							titleTextStyle={titleTextStyle}
						/>
					)}
				</Fragment>
			);
		});
	}
	function SetSockerbruksgatan() {
		return [
			[
				sensors.find(sen => sen.sensorid === '11319'),
				sensors.find(sen => sen.sensorid === '11320'),
				sensors.find(sen => sen.sensorid === '11321'),
				sensors.find(sen => sen.sensorid === '11322'),
				sensors.find(sen => sen.sensorid === '11323'),
			],
			[
				sensors.find(sen => sen.sensorid === '11324'),
				sensors.find(sen => sen.sensorid === '11304'),
				sensors.find(sen => sen.sensorid === '11325'),
				sensors.find(sen => sen.sensorid === '11326'),
				sensors.find(sen => sen.sensorid === '11327'),
			],
			[
				sensors.find(sen => sen.sensorid === '11501'),
				sensors.find(sen => sen.sensorid === '11502'),
				sensors.find(sen => sen.sensorid === '11503'),
				sensors.find(sen => sen.sensorid === '11504'),
				sensors.find(sen => sen.sensorid === '11510'),
			],
			[
				sensors.find(sen => sen.sensorid === '11506'),
				sensors.find(sen => sen.sensorid === '11507'),
				sensors.find(sen => sen.sensorid === '11508'),
				sensors.find(sen => sen.sensorid === '11509'),
				sensors.find(sen => sen.sensorid === '11505'),
			],
		].map((sens, i) => {
			for (const [i, sen] of sens.entries()) sens[i] = convertUnitAndValue(sen?.unit, sen?.value);

			return (
				<Fragment key={i}>
					{sens.map((sen, j) => (
						<div
							key={`${i}${j}`}
							style={{
								display: 'flex',
								position: 'absolute',
								top: `${67.4 + j * 18.0 - (j >= 2 ? 55.6 - (j - 2) * 1.25 : 0)}%`,
								left: `${6.5 + (j >= 2 ? 12.9 : 0) + i * 24.9}%`,
								fontWeight: '300',
								color: '#fffc',
								transform: 'translate(0, -50%)',
							}}
						>
							<div style={{ ...valueTextStyle, fontSize: `${3.6 * percentageDifference}rem` }}>{sen?.value}</div>
							<div
								style={{
									...unitTextStyle,
									fontSize: `${2.8 * percentageDifference}rem`,
									marginLeft: `${1.2 * percentageDifference}rem`,
								}}
							>
								{sen?.unit || ''}
							</div>
						</div>
					))}
				</Fragment>
			);
		});
	}

	function RenderDashboard() {
		let renderChildren;
		let thumbnail;
		let background;
		switch (props.currentProperty) {
			//Sosdala
			case 159:
				renderChildren = SetSosdal();
				thumbnail = require('../assets/dashboard/backer-logo.png').default;
				background = require('../assets/dashboard/bg.png').default
				break;
			// Eskilstuna kungsgatan 61
			case 213:
				renderChildren = SetEskilstuna();
				thumbnail = require('../assets/dashboard/eec-logo.png').default;
				background = require('../assets/dashboard/Eskilstuna-bg.png').default;
				break;
			// Ängelholm Sockerbruksgatan 7A & 9A
			case 311:
			case 312:
				renderChildren = SetSockerbruksgatan();
				thumbnail = require('../assets/dashboard/demo-thumbnail.png').default;
				background = require('../assets/dashboard/sockerbruksgatan-bg.png').default;
				break;
			//Demo properties
			case 160:
			case 161:
			case 162:
			case 163:
			case 164:
			case 165:
			case 166:
			case 167:
			case 168:
			case 169:
			case 170:
			case 171:
			case 172:
			case 173:
			case 174:
				renderChildren = SetDemoProperties();
				thumbnail = require('../assets/dashboard/demo-thumbnail.png').default;
				background = require('../assets/dashboard/demo-bg.png').default;
				break;
			case '':
				renderChildren = <div></div>;
				thumbnail = require('../assets/dashboard/demo-thumbnail.png').default;
				background = require('../assets/dashboard/demo-bg-strip.png').default;
				break;
			default:
				//renderChildren = <div></div>;
				//thumbnail = require('../assets/dashboard/demo-thumbnail.png').default;
				//background = require('../assets/dashboard/demo-bg.png').default;
		}

		if (thumbnail === undefined) {
			return <div></div>;
		}

		return (
			<Dashboard setPercentageDifference={setPercentageDifference} thumbnail={thumbnail} background={background} setIsFs={setIsFs}>
				{renderChildren}
			</Dashboard>
		);
	}

	return RenderDashboard();
}

export default connect(getStateVariables(STORE.sensors, STORE.currentProperty), undefined)(DashboardList);
