import React, { useState, useEffect } from 'react';
import { TextField, Button, Checkbox } from '@material-ui/core';
import { connect } from 'react-redux';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import axios from 'axios';

import { STORE, getStateVariables } from '../redux/selectors';
import { updateUserInfo } from '../redux/actionCreators';
import { CustomDivider } from './CustomDivider';
import EditPopup from './EditPopup';
import * as colors from '../colors';
import i18n from '../i18n';

const GENERAL_SETTINGS = Object.seal({
	email: { id: 'email', label: i18n.t('generic.email'), validityChecker: checkEmailValidity },
	phonenumber: { id: 'phonenumber', label: i18n.t('generic.phone'), validityChecker: checkPhonenumberValidity },
	firstName: { id: 'firstName', label: i18n.t('generic.firstName') },
	lastName: { id: 'lastName', label: i18n.t('generic.lastName') },
}); // BUG: Object.freeze will stop translations from updating
const PRICE_SETTINGS = Object.seal({
	electricity: { id: 'electricity', label: i18n.t('userAdmin.electricityPerKWH'), validityChecker: checkEnergyPriceValidity },
	water: { id: 'water', label: i18n.t('userAdmin.waterPerCM'), validityChecker: checkEnergyPriceValidity },
	districtHeating: { id: 'districtHeating', label: i18n.t('userAdmin.districtHeatingPerKWH'), validityChecker: checkEnergyPriceValidity },
}); // BUG: Object.freeze will stop translations from updating
const NOTIFICATION_SETTINGS = Object.seal({
	email: { id: 'email', label: i18n.t('generic.email') },
	sms: { id: 'sms', label: i18n.t('userAdmin.sms') },
	bms: { id: 'bms', label: i18n.t('userAdmin.DUCAlarm') },
}); // BUG: Object.freeze will stop translations from updating
const NOTIFICATION_ID = 'notificationOptions';
const ERROR_MSG = i18n.t('userAdmin.faultyValuesError');

const WEAK_PASSWORD = `weakPassword
passwordRequirements:
- min 12 characters
- at least one uppercase letter 
- at least one lowercase letter 
- at least one number`;

const GET_KEYCLOAK_USERS = gql`
	query ($filter: KeycloakUserFilter!) {
		getKeycloakUsers(filter: $filter) {
			id
			username
			email
			phonenumber
			firstName
			lastName
			propertyAccess
			propertyIds
			notificationOptions
			energyPrices
		}
	}
`;
const SET_KEYCLOAK_USERS = gql`
	mutation (
		$ids: [String!]!
		$email: String
		$phonenumber: String
		$firstName: String
		$lastName: String
		$propertyAccess: String
		$propertyIds: [Int!]
		$notificationOptions: [String!]
		$energyPrices: [Float!]
		$password: String
	) {
		setKeycloakUsers(
			ids: $ids
			email: $email
			phonenumber: $phonenumber
			firstName: $firstName
			lastName: $lastName
			propertyAccess: $propertyAccess
			propertyIds: $propertyIds
			notificationOptions: $notificationOptions
			energyPrices: $energyPrices
			password: $password
		) {
			id
			username
			email
			phonenumber
			firstName
			lastName
			propertyAccess
			propertyIds
			notificationOptions
			energyPrices
		}
	}
`;

function checkEmailValidity(email) {
	// Only allow uppercase and lowercase characters, numbers, @, and .
	for (const char of email.split('')) if (!char.match(/[a-z]|[A-Z]|[0-9]|@|\.|-|_/)) return false;
	return true;
}
function checkPhonenumberValidity(phonenumber) {
	// Only allow +, numbers, and spaces
	for (const char of phonenumber.split('')) if (!char.match(/\+|[0-9]| /)) return false;
	return true;
}
function checkEnergyPriceValidity(price) {
	// Only allow +, numbers, and spaces
	for (const char of price.split('')) if (!char.match(/\.|[0-9]/)) return false;
	return true;
}

/**
 * A list of user-settings that can be be changed and saved
 */
function UserAdministration(props) {
	const [oldSettings, setOldSettings] = useState({});
	const [newSettings, setNewSettings] = useState({});
	const [errorMessage, setErrorMessage] = useState('');
	const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
	const [showPasswordPopup, setShowPasswordPopup] = useState(false);
	const [currentPassword, setCurrentPassword] = useState('');
	const [currentPasswordMatch, setCurrentPasswordMatch] = useState(false);


	const userQuery = useQuery(GET_KEYCLOAK_USERS, {
		variables: { filter: { ids: [props.userInfo.id] } },
		skip: !(props.userInfo && props.userInfo.id),
	});
	const [setKeycloakUsers] = useMutation(SET_KEYCLOAK_USERS, {
		onCompleted: ({ setKeycloakUsers }) => setKeycloakUsers[0] && props.updateUserInfo(setKeycloakUsers[0]),
	});


	const { t } = useTranslation();

	useEffect(() => {
		if (!userQuery.loading && userQuery.data) {
			const user = userQuery.data.getKeycloakUsers[0];
			setOldSettings({
				[GENERAL_SETTINGS.email.id]: user.email || '',
				[GENERAL_SETTINGS.phonenumber.id]: user.phonenumber || '',
				[GENERAL_SETTINGS.firstName.id]: user.firstName || '',
				[GENERAL_SETTINGS.lastName.id]: user.lastName || '',
				[NOTIFICATION_ID]: user[NOTIFICATION_ID] || [],
				[PRICE_SETTINGS.electricity.id]: String(user.energyPrices?.[0] || ''),
				[PRICE_SETTINGS.water.id]: String(user.energyPrices?.[1] || ''),
				[PRICE_SETTINGS.districtHeating.id]: String(user.energyPrices?.[2] || ''),
			});
		}
		// eslint-disable-next-line
	}, [userQuery.loading, userQuery.data]);

	function saveChanges() {
		// Check that all inputs are valid
		for (const sett of Object.values({ ...GENERAL_SETTINGS, ...PRICE_SETTINGS })) {
			if (newSettings[sett.id] && typeof sett.validityChecker === 'function' && !sett.validityChecker(newSettings[sett.id])) {
				setErrorMessage(ERROR_MSG);
				return;
			}
			Swal.fire(t('userAdmin.success'), t('userAdmin.saveInfo'), 'success');
		}

		// Reset newSettings and errorMessage
		setNewSettings({});
		setErrorMessage('');

		setKeycloakUsers({
			variables: {
				ids: [props.userInfo.id],
				email: newSettings[GENERAL_SETTINGS.email.id],
				phonenumber: newSettings[GENERAL_SETTINGS.phonenumber.id],
				firstName: newSettings[GENERAL_SETTINGS.firstName.id],
				lastName: newSettings[GENERAL_SETTINGS.lastName.id],
				[NOTIFICATION_ID]: newSettings[NOTIFICATION_ID],
				energyPrices: [
					Number(newSettings[PRICE_SETTINGS.electricity.id] || oldSettings[PRICE_SETTINGS.electricity.id]),
					Number(newSettings[PRICE_SETTINGS.water.id] || oldSettings[PRICE_SETTINGS.water.id]),
					Number(newSettings[PRICE_SETTINGS.districtHeating.id] || oldSettings[PRICE_SETTINGS.districtHeating.id]),
				],
			},
		});
	}
	function isStrongPassword(password) {
		return password.length >= 12
			&& password.match(/[a-z]/)
			&& password.match(/[A-Z]/)
			&& password.match(/[0-9]/);
	}

	function drawTextFields(settings) {
		return Object.values(settings).map(sett => (
			<div key={sett.id}>
				<h3 style={{ fontSize: '110%', fontWeight: '400', color: '#000a', margin: '0 0 0 0.1rem' }}>{sett.label}</h3>
				<TextField
					value={newSettings[sett.id] || ''}
					placeholder={oldSettings[sett.id]}
					onChange={event => setNewSettings({ ...newSettings, [sett.id]: event.target.value })}
					error={Boolean(
						newSettings[sett.id] && typeof sett.validityChecker === 'function' && !sett.validityChecker(newSettings[sett.id])
					)}
					variant='outlined'
					size='small'
					style={{ margin: '0 0 0.8rem 0' }}
				/>
				<br />
			</div>
		));
	}
	// To check if the current password is correct while relogging using the new entered password
	useEffect(() => {
		const checkPassword = async () => {
			const client_id = process.env.REACT_APP_KEYCLOAK_CLIENTID;//'connect-front';
			const realm = process.env.REACT_APP_KEYCLOAK_REALM; //'connect';
			const keycloak_url = process.env.REACT_APP_KEYCLOAK_URL; //'https://login.meliox.se/auth';
			const auth_endpoint = `${keycloak_url}/realms/${realm}/protocol/openid-connect/token`;

			const data = {
				client_id: client_id,
				username: props?.userInfo?.username,
				password: currentPassword,
				grant_type: 'password',
			};
			const config = {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			};
			try {
				const response = await axios.post(auth_endpoint, new URLSearchParams(data), config);
				if (response.status === 200) {
					//console.log('Password is correct');
					setCurrentPasswordMatch(true);
				} else {
					//console.log('Password is incorrect');
					setCurrentPasswordMatch(false);
				}
			} catch (error) {
				//console.log('Failed to check password', error);
				setCurrentPasswordMatch(false);
			}
		};
		checkPassword();
	}, [currentPassword]);

	/* if (currentPasswordMatch) {
		console.log('Password is correct', currentPasswordMatch);
	}
	else {
		console.log('Password is incorrect', currentPasswordMatch);
	}
 */
	return (
		<div>
			<div style={{ display: 'flex', margin: '1rem 0 0 1rem' }}>
				{/* Column 1 */}
				<div style={{ width: '13rem', marginLeft: '0.5rem' }}>
					<h3 style={{ fontSize: '110%', fontWeight: '400', color: '#000a', marginBottom: '0.5rem' }}>{t('generic.userName')}</h3>
					<h2 style={{ fontSize: '130%', fontWeight: '400', color: '#000d', marginBottom: '1.5rem' }}>
						{props?.userInfo?.username}
					</h2>

					<h3 style={{ fontSize: '110%', fontWeight: '400', color: '#000a', marginBottom: '0.5rem' }}>{t('generic.password')}</h3>
					<Button
						variant='outlined'
						onClick={() => {
							setPasswordErrorMessage('');
							setShowPasswordPopup(!showPasswordPopup);
						}}
						style={{ marginBottom: '1.7rem', color: colors.primary }}
					>
						{t('userAdmin.changePassword')}
					</Button>

					<div>
						<h3 style={{ fontSize: '110%', fontWeight: '400', color: '#000a', marginBottom: '0.5rem' }}>
							{t('userAdmin.alarmNotifications')}
						</h3>

						{Object.values(NOTIFICATION_SETTINGS).map(sett => (
							<div key={sett.id} style={{ display: 'flex', marginTop: '-0.3rem' }}>
								<Checkbox
									checked={Boolean(
										newSettings[NOTIFICATION_ID]
											? newSettings[NOTIFICATION_ID]?.includes(sett.id)
											: oldSettings[NOTIFICATION_ID]?.includes(sett.id)
									)}
									onChange={(e, val) =>
										setNewSettings({
											...newSettings,
											[NOTIFICATION_ID]: val
												? [
													...new Set([
														...(newSettings[NOTIFICATION_ID] || oldSettings[NOTIFICATION_ID] || []),
														sett.id,
													]),
												]
												: (newSettings[NOTIFICATION_ID] || oldSettings[NOTIFICATION_ID] || []).filter(
													opt => opt !== sett.id
												),
										})
									}
									style={{ marginLeft: '-9px', color: colors.primary }}
								/>

								<h4 style={{ fontSize: '120%', fontWeight: '400', color: '#000e', margin: 'auto 0' }}>{sett.label}</h4>
							</div>
						))}
					</div>
				</div>

				<CustomDivider isVertical disableEndMargin marginSideOffset={12} style={{ height: '21rem' }} />

				{/* Column 2 */}
				<div style={{ width: '17rem' }}>{drawTextFields(GENERAL_SETTINGS)}</div>

				<CustomDivider isVertical disableEndMargin marginSideOffset={12} style={{ height: '21rem' }} />

				{/* Column 3 */}
				<div>
					<h3 style={{ fontSize: '119%', fontWeight: '400', color: '#000a', margin: '0 0 0.5rem 0' }}>
						{t('userAdmin.energyPrices')}
					</h3>
					{drawTextFields(PRICE_SETTINGS)}
				</div>
			</div>

			{/* Cancel and Save buttons */}
			<div style={{ display: 'flex', margin: '0.5rem 0 1rem 0' }}>
				<div style={{ color: '#f00', fontSize: '115%', fontWeight: '500', margin: '1.7rem 1.5rem auto auto' }}>{errorMessage}</div>

				<Button
					variant='outlined'
					onClick={() => {
						setNewSettings({});
						setErrorMessage('');
						Swal.fire(t('userAdmin.cancelled'), t('userAdmin.cancelInfo'), 'error');
					}}
					disabled={!Object.keys(newSettings).some(key => newSettings[key])}
					style={{ margin: '1rem 0.5rem 0 0', width: '7rem', height: '2.8rem', color: colors.primary }}
				>
					{t('generic.cancel')}
				</Button>

				<Button
					variant='outlined'
					onClick={saveChanges}
					disabled={!Object.keys(newSettings).some(key => newSettings[key])}
					style={{ margin: '1rem 0.8rem 0 1rem', width: '7rem', height: '2.8rem', color: colors.primary }}
				>
					{t('generic.save')}
				</Button>
			</div>

			{/* Password edit popup */}
			<EditPopup
				isOpen={showPasswordPopup}
				text={{
					title: t('userAdmin.changePassword'),
					subtitle: t('userAdmin.provideNewPasswordUser'),
					error: passwordErrorMessage,
				}}
				fields={[
					{
						id: 'currentPassword',
						label: t('userAdmin.currentPassword'),
						elementType: 'password',
						required: true,
						onChange: (e) => {
							//e.prevntDefault();
							setCurrentPassword(e?.target?.value || e);
						}
					},
					{
						id: 'password',
						label: t('userAdmin.newPassword'),
						elementType: 'password',
						required: true,
						required_rule_text: WEAK_PASSWORD,
						required_rule: password => !isStrongPassword(password),
					},
					{
						id: 'confirmPassword',
						label: t('userAdmin.confirmNewPassword'),
						elementType: 'password',
						required: true,
					},
				]}
				onClose={() => {
					Swal.fire(t('userAdmin.cancelled'), t('userAdmin.cancelPassword'), 'error');
					setShowPasswordPopup(!showPasswordPopup);
				}}
				onSave={vals => {
					if (currentPasswordMatch) {
						//current password matches with new password
						if (vals.password === currentPassword) {
							setPasswordErrorMessage(t('userAdmin.newPasswordSame'));
						} else if (vals.password === vals.confirmPassword) {
							setKeycloakUsers({ variables: { ids: [props.userInfo.id], password: vals.password } });
							setShowPasswordPopup(!showPasswordPopup);
							Swal.fire(t('userAdmin.success'), t('userAdmin.savePassword'), 'success');
						} else {
							setPasswordErrorMessage(t('userAdmin.newPasswordNotSame'));
						}
					} else {
						setPasswordErrorMessage(t('userAdmin.currentPasswordNotSame'));
					}
				}}
			/>
		</div>
	);
}

export default connect(getStateVariables(STORE.userInfo), { updateUserInfo })(UserAdministration);
